<template>
	<v-row align="center" justify="center" style="padding-top: 2rem; padding-bottom: 2rem">
		<v-col cols="11">
			<div class="back" @click="$router.push('/')">
				<svg
					role="img"
					xmlns="http://www.w3.org/2000/svg"
					width="24px"
					height="24px"
					viewBox="0 0 24 24"
					aria-labelledby="chevronLeftIconTitle"
					stroke="#141a28"
					stroke-width="2"
					stroke-linecap="square"
					stroke-linejoin="miter"
					fill="none"
					color="#141a28"
					class="back__svg"
				>
					<title id="chevronLeftIconTitle">Chevron Left</title>
					<polyline points="14 18 8 12 14 6 14 6" />
				</svg>
				<div class="back__title">{{ locale[lang].to_home }}</div>
			</div>
			<div v-if="info" class="entry">
				<div class="entry__title">{{ info.title }}</div>
				<div class="entry__content" v-html="info.content"></div>
			</div>
			<div v-else class="entry">
				<div class="entry__content">
					{{ locale[lang].nothing_found }}
				</div>
			</div>
		</v-col>
	</v-row>
</template>

<style>
.entry {
	background-color: #fff;
	box-shadow: 2px 2px 1px 1px rgb(32 85 215 / 5%);
	border-radius: 5px;
	padding: 30px 50px;
}
.entry__title {
	font-size: 18px;
	font-weight: 600;
	color: #141a28;
}
.entry__content {
	margin-top: 20px;
}
</style>

<script>
import axios from "axios";

export default {
	name: "PageShow",
	props: ["locale"],
	data: () => ({
		info: null,
	}),
	computed: {
		backend_url() {
			return this.$store.getters.BACKEND_URL;
		},
		lang() {
			return this.$store.getters.LANG;
		},
	},
	mounted() {
		this.updateInfo();
	},
	methods: {
		updateInfo() {
			this.$store.commit("SET_PRELOADER", true);

			axios
				.get(this.backend_url + "/api/page", {
					params: {
						id: this.$route.params.id,
					},
				})
				.then((response) => {
					if (Object.keys(response.data).length) {
						this.info = response.data;
					}
				})
				.finally(() => {
					this.$store.commit("SET_PRELOADER", false);
				});
		},
	},
};
</script>
