import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		backend_url: 'https://admin.akptit-keste.kz',
		preloader: false,
		lang: 'ru',
		shift: 1,
		course: 1,
	},
	getters: {
		BACKEND_URL: (state) => {
			return state.backend_url;
		},
		PRELOADER: (state) => {
			return state.preloader;
		},
		LANG: (state) => {
			return state.lang;
		},
		SHIFT: (state) => {
			return state.shift;
		},
		COURSE: (state) => {
			return state.course;
		},
	},
	mutations: {
		SET_PRELOADER: (state, payload) => {
			state.preloader = payload;
		},
		SET_LANG: (state, payload) => {
			state.lang = payload;
		},
		SET_SHIFT: (state, payload) => {
			state.shift = payload;
		},
		SET_COURSE: (state, payload) => {
			state.course = payload;
		},
	},
	actions: {},
	modules: {},
});
