<template>
	<v-row
		align="center"
		justify="center"
	>
		<v-col
			cols="11"
			xl="4"
			lg="4"
			md="6"
			sm="10"
		>
			<div class="logotype">
				<img src="../assets/logotype.png" alt="" class="logotype__img">
			</div>
			<div
				class="back"
				@click="$router.push('/')"
			>
				<svg
					role="img"
					xmlns="http://www.w3.org/2000/svg"
					width="24px"
					height="24px"
					viewBox="0 0 24 24"
					aria-labelledby="chevronLeftIconTitle"
					stroke="#141a28"
					stroke-width="2"
					stroke-linecap="square"
					stroke-linejoin="miter"
					fill="none"
					color="#141a28"
					class="back__svg"
				>
					<title id="chevronLeftIconTitle">Chevron Left</title>
					<polyline points="14 18 8 12 14 6 14 6" />
				</svg>
				<div class="back__title">{{ locale[lang].to_home }}</div>
			</div>
			<!--<div class="navs">
				<div
					class="nav"
					@click="$router.push('/choose-course')"
				>
					<svg
						role="img"
						xmlns="http://www.w3.org/2000/svg"
						width="24px"
						height="24px"
						viewBox="0 0 24 24"
						aria-labelledby="chevronLeftIconTitle"
						stroke="#141a28"
						stroke-width="2"
						stroke-linecap="square"
						stroke-linejoin="miter"
						fill="none"
						color="#141a28"
						class="nav__svg"
					>
						<title id="chevronLeftIconTitle">Chevron Left</title>
						<polyline points="14 18 8 12 14 6 14 6" />
					</svg>
				</div>
				<div
					class="nav"
					@click="$router.push('/')"
				>
					<svg
						role="img"
						xmlns="http://www.w3.org/2000/svg"
						width="24px"
						height="24px"
						viewBox="0 0 24 24"
						aria-labelledby="laptopIconTitle"
						stroke="#141a28"
						stroke-width="2"
						stroke-linecap="square"
						stroke-linejoin="miter"
						fill="none"
						color="#141a28"
						class="nav__svg"
					>
						<title id="laptopIconTitle">Laptop</title>
						<path d="M20 7v11H4V7a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1z" />
						<path d="M2 18h20M4 17h16" />
					</svg>
				</div>
			</div>-->
			<div class="heading">
				<svg
					role="img"
					xmlns="http://www.w3.org/2000/svg"
					width="32px"
					height="32px"
					viewBox="0 0 24 24"
					aria-labelledby="bookOpenedIconTitle"
					stroke="#141a28"
					stroke-width="1.5"
					stroke-linecap="square"
					stroke-linejoin="miter"
					fill="none"
					color="#141a28"
					class="heading__svg"
				>
					<title id="bookOpenedIconTitle">Book</title>
					<path
						d="M12 6s-2-2-4-2-5 2-5 2v14s3-2 5-2 4 2 4 2c1.333-1.333 2.667-2 4-2 1.333 0 3 .667 5 2V6c-2-1.333-3.667-2-5-2-1.333 0-2.667.667-4 2z"
					/>
					<path stroke-linecap="round" d="M12 6v14" />
				</svg>
				<div class="heading__title">{{ locale[lang].ChooseGroupTitle }}</div>
			</div>
			<div
				class="checkers is-group"
			>
				<!--NOTE: OLD METHOD FOR CLICK: @click="changeGroup(item)"-->
				<div
					v-for="item in items"
					:key="item.id"
					:class="group && item.id === group.id ? 'checker is-active' : 'checker'"
					@click="showSchedule(item)"
				>
					<div class="checker__status">
						<svg
							role="img"
							xmlns="http://www.w3.org/2000/svg"
							width="16px"
							height="16px"
							viewBox="0 0 24 24"
							aria-labelledby="okIconTitle"
							stroke="#2055d7"
							stroke-width="3"
							stroke-linecap="square"
							stroke-linejoin="miter"
							fill="none"
							color="#2055d7"
							class="checker__status-svg"
						>
							<title id="okIconTitle">Ok</title>
							<polyline points="4 13 9 18 20 7" />
						</svg>
					</div>
					<div class="checker__title">{{ item.title }}</div>
				</div>
			</div>
			<!--<div
				:class="group ? 'go is-active' : 'go'"
				@click="group ? $router.push({
					name: 'GroupSchedule',
					params: {
						group: group
					}
				}) : false"
			>
				<svg
					role="img"
					xmlns="http://www.w3.org/2000/svg"
					width="24px"
					height="24px"
					viewBox="0 0 24 24"
					aria-labelledby="chevronRightIconTitle"
					stroke="#a1abc3"
					stroke-width="2"
					stroke-linecap="square"
					stroke-linejoin="miter"
					fill="none"
					color="#a1abc3"
					class="go__svg"
				>
					<title id="chevronRightIconTitle">Chevron Right</title>
					<polyline points="10 6 16 12 10 18 10 18" />
				</svg>
				<div class="go__title">{{ locale[lang].to_schedule }}</div>
			</div>-->
			<div
				class="back"
				@click="$router.push('/choose-course')"
			>
				<svg
					role="img"
					xmlns="http://www.w3.org/2000/svg"
					width="24px"
					height="24px"
					viewBox="0 0 24 24"
					aria-labelledby="chevronLeftIconTitle"
					stroke="#141a28"
					stroke-width="2"
					stroke-linecap="square"
					stroke-linejoin="miter"
					fill="none"
					color="#141a28"
					class="back__svg"
				>
					<title id="chevronLeftIconTitle">Chevron Left</title>
					<polyline points="14 18 8 12 14 6 14 6" />
				</svg>
				<div class="back__title">{{ locale[lang].to_back }}</div>
			</div>
		</v-col>
	</v-row>
</template>

<script>
	import axios from 'axios'

	export default {
		name: 'ChooseGroup',
		props: [
			'locale'
		],
		data: () => ({
			groups: [],
			group: null
		}),
		computed: {
			backend_url () {
				return this.$store.getters.BACKEND_URL
			},
			lang () {
				return this.$store.getters.LANG
			},
			shift () {
				return this.$store.getters.SHIFT
			},
			course () {
				return this.$store.getters.COURSE
			},
			items () {
				let items = []

				this.groups.forEach(item => {
					if(item.lang === this.lang && item.shift === this.shift && item.course === this.course) {
						items.push(item)
					}
				})

				return items
			}
		},
		mounted () {
			window.scroll(0, 0)

			this.$store.commit('SET_PRELOADER', true)

			axios
				.get(this.backend_url + '/api/groups')
				.then(response => {
					this.groups = response.data
				})
				.finally(() => {
					this.$store.commit('SET_PRELOADER', false)
				})
		},
		methods: {
			changeGroup (group) {
				this.group = group
			},
			showSchedule (item) {
				this.$router.push({
					name: 'GroupSchedule',
					params: {
						group: item
					}
				})
			}
		}
	}
</script>
