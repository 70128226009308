<template>
	<v-app class="v1-app">
		<router-view :locale="locale" />
		<v-overlay
			v-if="preloader"
			opacity=".5"
		>
			<v-progress-circular
				align="center"
				indeterminate
				size="64"
			></v-progress-circular>
		</v-overlay>
	</v-app>
</template>

<style lang="scss">
	body {
		background-color: #e7edfd;
	}
	.v1-app {
		background-color: #e7edfd !important;
		background-image: linear-gradient(to bottom, #eff3fe, #e7edfd) !important;
		background-image: -webkit-linear-gradient(to bottom, #eff3fe, #e7edfd) !important;
	}
	.logotype {
		margin-top: 30px;
		margin-bottom: 30px;
	}
		.logotype__img {
			display: block;
		}
	.back {
		display: flex;
		align-items: center;
		cursor: pointer;
		margin-bottom: 30px;
		&:hover {
			& .back__svg {
				stroke: #2055d7;
				color: #2055d7;
			}
			& .back__title {
				color: #2055d7;
			}
		}
	}
		.back__svg {
			flex-shrink: 0;
			margin-right: 20px;
			transition-duration: 0.3s;
		}
		.back__title {
			font-size: 16px;
			font-weight: 600;
			color: #141a28;
			transition-duration: 0.3s;
		}
	.navs {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
	}
		.nav {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #fff;
			box-shadow: 2px 2px 1px 1px rgba(32, 85, 215, 0.05);
			border-radius: 5px;
			padding: 10px;
			margin-bottom: 20px;
			cursor: pointer;
			transition-duration: 0.3s;
			&:hover {
				background-color: #2055d7;
				& .nav__svg {
					stroke: #fff;
					color: #fff;
				}
			}
		}
			.nav__svg {
				transition-duration: 0.3s;
			}
	.heading {
		display: flex;
		align-items: center;
		background-color: #fff;
		box-shadow: 2px 2px 1px 1px rgba(32, 85, 215, 0.05);
		border-radius: 5px;
		padding: 12.5px 50px;
		margin-bottom: 40px;
	}
		.heading__svg {
			flex-shrink: 0;
			margin-right: 20px;
		}
		.heading__title {
			font-weight: 600;
			color: #141a28;
		}
	.checkers {
		margin-bottom: 40px;
		&.is-group {
			@media (min-width: 992px)
			{
				display: flex;
				flex-wrap: wrap;
				margin-right: -10px;
				margin-left: -10px;
				& .checker {
					flex-shrink: 0;
					width: unquote('calc(100% / 2 - 20px)');
					margin-right: 10px;
					margin-left: 10px;
				}
			}
		}
	}
		.checker {
			display: flex;
			align-items: center;
			background-color: #fff;
			border: 1px solid transparent;
			box-shadow: 2px 2px 1px 1px rgba(32, 85, 215, 0.05);
			border-radius: 5px;
			padding: 12.5px 50px;
			margin-bottom: 20px;
			cursor: pointer;
			transition-duration: 0.3s;
			&:last-child {
				margin-bottom: 0;
			}
			&.is-active {
				border-color: #2055d7;
				& .checker__status {
					border-color: #2055d7;
				}
				& .checker__status-svg {
					opacity: 1;
				}
				& .checker__title {
					color: #2055d7;
				}
			}
		}
			.checker__status {
				display: none;
				flex-shrink: 0;
				align-items: center;
				justify-content: center;
				width: 24px;
				height: 24px;
				border: 1px solid #d9e0f0;
				border-radius: 5px;
				margin-right: 20px;
				transition-duration: 0.3s;
			}
				.checker__status-svg {
					opacity: 0;
					transition-duration: 0.3s;
				}
			.checker__title {
				font-weight: 600;
				color: #141a28;
				transition-duration: 0.3s;
			}
	.works {
		margin-bottom: 40px;
	}
		.work {
			position: relative;
			background-color: #fff;
			border: 1px solid transparent;
			box-shadow: 2px 2px 1px 1px rgba(32, 85, 215, 0.05);
			border-radius: 5px;
			margin-bottom: 20px;
		}
			.work__day {
				position: absolute;
				top: -1px;
				right: -1px;
				background-color: #d9e0f0;
				border-top-right-radius: 5px;
				border-bottom-left-radius: 5px;
				font-size: 12px;
				font-weight: 500;
				color: #a1abc3;
				padding: 3.125px 12.5px;
				&.is-monday {
					background-color: #fbc02d;
					color: #fff;
				}
				&.is-tuesday {
					background-color: #ec407a;
					color: #fff;
				}
				&.is-wednesday {
					background-color: #4aaf50;
					color: #fff;
				}
				&.is-thursday {
					background-color: #fb8c00;
					color: #fff;
				}
				&.is-friday {
					background-color: #2196f3;
					color: #fff;
				}
				&.is-saturday {
					background-color: #9c27b0;
					color: #fff;
				}
			}
			.work__items {}
				.work__item {
					border-bottom: 1px dashed #d9e0f0;
					padding-top: 24.5px;
					&:last-child {
						border-bottom-width: 0;
					}
				}
					.work__item-time {
						flex-shrink: 0;
						font-size: 14px;
						color: #2055d7;
						padding-right: 25px;
						padding-left: 25px;
						margin-bottom: 6.25px;
					}
					.work__item-info {
						padding-right: 25px;
						padding-left: 25px;
						margin-bottom: 12.5px;
					}
						.work__item-teacher {
							font-size: 14px;
							color: #999;
						}
	.go {
		display: flex;
		align-items: center;
		background-color: #d9e0f0;
		border: 1px solid transparent;
		box-shadow: 2px 2px 1px 1px rgba(217, 224, 240, 0.05);
		border-radius: 5px;
		padding: 12.5px 50px;
		margin-bottom: 40px;
		transition-duration: 0.3s;
		&.is-active {
			background-color: #2055d7;
			box-shadow: 2px 2px 1px 1px rgba(32, 85, 215, 0.05);
			cursor: pointer;
			&:hover, &:active {
				background-color: #5acee8;
				box-shadow: 2px 2px 1px 1px rgba(90, 206, 232, 0.05);
			}
			& .go__svg {
				stroke: #fff;
				color: #fff;
			}
			& .go__title {
				color: #fff;
			}
		}
	}
		.go__svg {
			flex-shrink: 0;
			margin-right: 20px;
			transition-duration: 0.3s;
		}
		.go__title {
			font-weight: 600;
			color: #a1abc3;
			transition-duration: 0.3s;
		}
</style>

<script>
	export default {
		name: 'App',
		data: () => ({
			locale: {
				kz: {
					ChooseLangTitle: 'Оқыту тілі',
					ChooseShiftTitle: 'Жаттығуды өзгерту',
					ChooseCourseTitle: 'Оқу курсы',
					ChooseGroupTitle: 'Жаттығу тобы',
					GroupScheduleTitle: 'Сабақ кестесі',
					go: 'Әрі қарай жүріңіз',
					to_back: 'Қайту',
					to_home: 'Басты бетке оралу',
					to_schedule: 'Кестені көрсету',
					monday: 'Дүйсенбі',
					tuesday: 'Сейсенбі',
					wednesday: 'Сәрсенбі',
					thursday: 'Бейсенбі',
					friday: 'Жұма',
					saturday: 'Сенбі',
					nothing_found: 'Ештеңе табылмады.'
				},
				ru: {
					ChooseLangTitle: 'Язык обучения',
					ChooseShiftTitle: 'Смена обучения',
					ChooseCourseTitle: 'Курс обучения',
					ChooseGroupTitle: 'Группа обучения',
					GroupScheduleTitle: 'Расписание занятий',
					go: 'Перейти дальше',
					to_back: 'Вернуться назад',
					to_home: 'Вернуться на главную',
					to_schedule: 'Показать расписание',
					monday: 'Понедельник',
					tuesday: 'Вторник',
					wednesday: 'Среда',
					thursday: 'Четверг',
					friday: 'Пятница',
					saturday: 'Суббота',
					nothing_found: 'Ничего не найдено.'
				},
				en: {
					ChooseLangTitle: 'Language of education',
					ChooseShiftTitle: 'Change of training',
					ChooseCourseTitle: 'Course of Study',
					ChooseGroupTitle: 'Training group',
					GroupScheduleTitle: 'Timetable of classes',
					go: 'Go further',
					to_back: 'Come back',
					to_home: 'Go back to the main page',
					to_schedule: 'Show schedule',
					monday: 'Monday',
					tuesday: 'Tuesday',
					wednesday: 'Wednesday',
					thursday: 'Thursday',
					friday: 'Friday',
					saturday: 'Saturday',
					nothing_found: 'Nothing found.'
				}
			}
		}),
		computed: {
			preloader () {
				return this.$store.getters.PRELOADER
			}
		}
	}
</script>
