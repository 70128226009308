<template>
	<v-row
		align="center"
		justify="center"
	>
		<v-col
			cols="11"
			xl="4"
			lg="4"
			md="6"
			sm="10"
		>
			<div class="logotype">
				<img src="../assets/logotype.png" alt="" class="logotype__img">
			</div>
			<div
				class="back"
				@click="$router.push('/')"
			>
				<svg
					role="img"
					xmlns="http://www.w3.org/2000/svg"
					width="24px"
					height="24px"
					viewBox="0 0 24 24"
					aria-labelledby="chevronLeftIconTitle"
					stroke="#141a28"
					stroke-width="2"
					stroke-linecap="square"
					stroke-linejoin="miter"
					fill="none"
					color="#141a28"
					class="back__svg"
				>
					<title id="chevronLeftIconTitle">Chevron Left</title>
					<polyline points="14 18 8 12 14 6 14 6" />
				</svg>
				<div class="back__title">{{ locale[lang].to_home }}</div>
			</div>
			<div class="heading">
				<svg
					role="img"
					xmlns="http://www.w3.org/2000/svg"
					width="32px"
					height="32px"
					viewBox="0 0 24 24"
					aria-labelledby="bookOpenedIconTitle"
					stroke="#141a28"
					stroke-width="1.5"
					stroke-linecap="square"
					stroke-linejoin="miter"
					fill="none"
					color="#141a28"
					class="heading__svg"
				>
					<title id="bookOpenedIconTitle">Book</title>
					<path
						d="M12 6s-2-2-4-2-5 2-5 2v14s3-2 5-2 4 2 4 2c1.333-1.333 2.667-2 4-2 1.333 0 3 .667 5 2V6c-2-1.333-3.667-2-5-2-1.333 0-2.667.667-4 2z"
					/>
					<path stroke-linecap="round" d="M12 6v14" />
				</svg>
				<div class="heading__title">{{ locale[lang].ChooseLangTitle }}</div>
			</div>
			<div class="checkers">
				<div
					v-for="item in langs"
					:key="item.key"
					:class="item.key === lang ? 'checker is-active' : 'checker'"
					@click="changeLang(item)"
				>
					<div class="checker__status">
						<svg
							role="img"
							xmlns="http://www.w3.org/2000/svg"
							width="16px"
							height="16px"
							viewBox="0 0 24 24"
							aria-labelledby="okIconTitle"
							stroke="#2055d7"
							stroke-width="3"
							stroke-linecap="square"
							stroke-linejoin="miter"
							fill="none"
							color="#2055d7"
							class="checker__status-svg"
						>
							<title id="okIconTitle">Ok</title>
							<polyline points="4 13 9 18 20 7" />
						</svg>
					</div>
					<div class="checker__title">{{ item.value }}</div>
				</div>
			</div>
			<div
				:class="lang ? 'go is-active mb-0' : 'go mb-0'"
				@click="$router.push('/choose-shift')"
			>
				<svg
					role="img"
					xmlns="http://www.w3.org/2000/svg"
					width="24px"
					height="24px"
					viewBox="0 0 24 24"
					aria-labelledby="chevronRightIconTitle"
					stroke="#a1abc3"
					stroke-width="2"
					stroke-linecap="square"
					stroke-linejoin="miter"
					fill="none"
					color="#a1abc3"
					class="go__svg"
				>
					<title id="chevronRightIconTitle">Chevron Right</title>
					<polyline points="10 6 16 12 10 18 10 18" />
				</svg>
				<div class="go__title">{{ locale[lang].go }}</div>
			</div>
		</v-col>
	</v-row>
</template>

<script>
	export default {
		name: 'ChooseLang',
		props: [
			'locale'
		],
		data: () => ({
			langs: [{
				key: 'kz',
				value: 'Қазақша'
			}, {
				key: 'ru',
				value: 'Русский'
			}, {
				key: 'en',
				value: 'English'
			}]
		}),
		computed: {
			lang () {
				return this.$store.getters.LANG
			}
		},
		mounted () {
			window.scroll(0, 0)
		},
		methods: {
			changeLang (lang) {
				this.$store.commit('SET_LANG', lang.key)
			}
		}
	}
</script>
