<template>
	<div class="body">
		<div class="body__content">
			<div class="langs">
				<div
					:class="current_lang === 'kz' ? 'lang is-active' : 'lang'"
					@click="current_lang = 'kz'"
				>
					<img src="../assets/206778.png" alt="" class="lang__flag">
					<div class="lang__title">Қазақша</div>
				</div>
				<div
					:class="current_lang === 'ru' ? 'lang is-active' : 'lang'"
					@click="current_lang = 'ru'"
				>
					<img src="../assets/555451.png" alt="" class="lang__flag">
					<div class="lang__title">Русский</div>
				</div>
				<div
					:class="current_lang === 'en' ? 'lang is-active' : 'lang'"
					@click="current_lang = 'en'"
				>
					<img src="../assets/555417.png" alt="" class="lang__flag">
					<div class="lang__title">English</div>
				</div>
			</div>
			<div class="circles">
				<div class="circles__list">
					<div class="circle is-logo is-mobile-only">
						<div class="circle__content">
							<img src="../assets/logo.png" alt="" class="circle__logo">
						</div>
					</div>
					<div @click="$router.push('/pages/1')" class="circle">
						<div class="circle__content">
							<img src="../assets/921347.png" alt="" class="circle__img">
							<div class="circle__title" v-html="langs[current_lang].priem"></div>
						</div>
					</div>
					<div
						class="circle is-margin-to-top"
						@click="$router.push('/choose-lang')"
					>
						<div class="circle__content">
							<img src="../assets/3891684.png" alt="" class="circle__img">
							<div class="circle__title" v-html="langs[current_lang].schedule"></div>
						</div>
					</div>
					<div @click="$router.push('/pages/2')" class="circle">
						<div class="circle__content">
							<img src="../assets/2416666.png" alt="" class="circle__img">
							<div class="circle__title" v-html="langs[current_lang].admin"></div>
						</div>
					</div>
				</div>
				<div class="circles__list is-center">
					<div @click="$router.push('/pages/3')" class="circle">
						<div class="circle__content">
							<img src="../assets/2436654.png" alt="" class="circle__img">
							<div class="circle__title" v-html="langs[current_lang].teachers"></div>
						</div>
					</div>
					<div class="circle is-logo">
						<div class="circle__content">
							<img src="../assets/logo.png" alt="" class="circle__logo">
						</div>
					</div>
					<div @click="$router.push('/pages/8')" class="circle">
						<div class="circle__content">
							<img src="../assets/5390399.png" alt="" class="circle__img">
							<div class="circle__title" v-html="langs[current_lang].ads"></div>
						</div>
					</div>
				</div>
				<div class="circles__list">
					<div @click="$router.push('/pages/10')" class="circle">
						<div class="circle__content">
							<img src="../assets/3048620.png" alt="" class="circle__img">
							<div class="circle__title" v-html="langs[current_lang].guide"></div>
						</div>
					</div>
					<div @click="$router.push('/pages/5')" class="circle is-margin-to-bottom">
						<div class="circle__content">
							<img src="../assets/1722398.png" alt="" class="circle__img">
							<div class="circle__title" v-html="langs[current_lang].mtb"></div>
						</div>
					</div>
					<div @click="$router.push('/pages/11')" class="circle">
						<div class="circle__content">
							<img src="../assets/2039807.png" alt="" class="circle__img">
							<div class="circle__title" v-html="langs[current_lang].other"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@100;200;300;400;500;600;700;800;900&display=swap');
.body {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	font-family: 'Commissioner', sans-serif;
}
.body__content {}
.langs {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	position: absolute;
	top: 20px;
	right: 20px;
}
.lang {
	display: flex;
	align-items: center;
	margin-right: 10px;
	margin-bottom: 10px;
	margin-left: 10px;
	cursor: pointer;
	transition-duration: .3s;
}
.lang:hover {
	opacity: 0.7;
}
.lang.is-active .lang__title {
	text-decoration: underline;
}
.lang__flag {
	flex-shrink: 0;
	width: 24px;
	height: 24px;
	margin-right: 10px;
}
.lang__title {
	font-weight: 500;
}
.circles {}
.circles__list {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 620px;
	margin-right: auto;
	margin-left: auto;
}
.circles__list:last-child {
	margin-bottom: 0;
}
.circles__list.is-center {
	width: 790px;
}
.circle {
	flex-shrink: 0;
	width: 190px;
	height: 190px;
	box-shadow: 10px 10px 10px rgba(0, 0, 0, .05);
	border-radius: 100%;
	cursor: pointer;
}
.circle.is-margin-to-top {
	position: relative;
	top: -50px;
}
.circle.is-margin-to-bottom {
	position: relative;
	bottom: -50px;
}
.circle.is-logo {
	width: 250px;
	height: 250px;
}
.circle:hover .circle__content {
	color: #2055d7;
}
.circle__content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	background-color: rgba(255, 255, 255, 1);
	border-radius: 100%;
	padding: 5px;
	overflow: hidden;
	transition-duration: .3s;
}
.circle__img {
	width: 50px;
	height: 50px;
	margin-bottom: 0.5rem;
}
.circle__title {
	text-align: center;
	font-size: 17px;
	font-weight: 700;
}
.circle__logo {
	max-width: 100%;
	max-height: 100%;
	object-fit: cover;
}
.is-mobile-only {
	display: none;
}
@media (max-width: 1366px) {
	.langs {
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
	}
	.circles__list {
		width: 600px;
	}
	.circles__list.is-center {
		width: 750px;
	}
	.circle {
		width: 170px;
		height: 170px;
	}
	.circle.is-logo {
		width: 200px;
		height: 200px;
	}
	.circle__title {
		font-size: 14px;
	}
}
@media (max-width: 767px) {
	.body {
		display: block;
	}
	.body__content {
		padding: 20px;
	}
	.langs {
		position: static;
		flex-direction: row;
		margin-bottom: 0;
	}
	.circles__list,
	.circle__list.is-center	{
		display: block !important;
		width: 100% !important;
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
	.circle,
	.circle.is-logo {
		width: 100%;
		border-radius: 3px;
		margin-bottom: 20px;
	}
	.circle.is-logo {
		display: none;
	}
	.circle__content {
		border-radius: 3px;
	}
	.circle.is-margin-to-top,
	.circle.is-margin-to-bottom {
		position: static;
	}
	.circle__logo {
		max-width: 80%;
		max-height: 80%;
		mix-blend-mode: darken;
	}
	.circle.is-mobile-only {
		display: block;
		height: 170px;
		box-shadow: none;
		padding: 0;
		margin-bottom: 0;
	}
	.circle.is-mobile-only .circle__content {
		background-color: transparent;
	}
}
</style>

<script>
	export default {
		name: 'Home',
		props: [
			'locale'
		],
		data: () => ({
			current_lang: 'ru',
			langs: {
				kz: {
					schedule: 'Online<br>Keste',
					admin: 'Колледж<br>Әкімшілігi',
					ads: 'Басқарма<br>хабарлама',
					other: 'Басқа<br>ақпарат',
					mtb: 'MTB<br>колледж',
					guide: 'Гид',
					teachers: 'Мұғалімдер<br>туралы мәлімет',
					priem: 'Азаматтарды<br>қабылдау'
				},
				ru: {
					schedule: 'Online<br>Keste',
					admin: 'Администрация<br>колледжа',
					ads: 'Доска<br>объявлений',
					other: 'Другая<br>информация',
					mtb: 'МТБ<br>колледжа',
					guide: 'Путеводитель',
					teachers: 'Информация о<br>преподавателях',
					priem: 'Приём<br>граждан'
				},
				en: {
					schedule: 'Online<br>Keste',
					admin: 'College<br>Administration',
					ads: 'Board<br>announcements',
					other: 'Other<br>information',
					mtb: 'MTB<br>college',
					guide: 'Guide',
					teachers: 'Information about<br>teachers',
					priem: 'Reception<br>citizens'
				}
			}
		})
	}
</script>
