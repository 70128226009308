<template>
	<v-row
		align="center"
		justify="center"
	>
		<v-col cols="11">
			<div class="logotype">
				<img src="../assets/logotype.png" alt="" class="logotype__img">
			</div>
			<div
				class="back"
				@click="$router.push('/')"
			>
				<svg
					role="img"
					xmlns="http://www.w3.org/2000/svg"
					width="24px"
					height="24px"
					viewBox="0 0 24 24"
					aria-labelledby="chevronLeftIconTitle"
					stroke="#141a28"
					stroke-width="2"
					stroke-linecap="square"
					stroke-linejoin="miter"
					fill="none"
					color="#141a28"
					class="back__svg"
				>
					<title id="chevronLeftIconTitle">Chevron Left</title>
					<polyline points="14 18 8 12 14 6 14 6" />
				</svg>
				<div class="back__title">{{ locale[lang].to_home }}</div>
			</div>
			<div class="heading">
				<svg
					role="img"
					xmlns="http://www.w3.org/2000/svg"
					width="32px"
					height="32px"
					viewBox="0 0 24 24"
					aria-labelledby="bookOpenedIconTitle"
					stroke="#141a28"
					stroke-width="1.5"
					stroke-linecap="square"
					stroke-linejoin="miter"
					fill="none"
					color="#141a28"
					class="heading__svg"
				>
					<title id="bookOpenedIconTitle">Book</title>
					<path
						d="M12 6s-2-2-4-2-5 2-5 2v14s3-2 5-2 4 2 4 2c1.333-1.333 2.667-2 4-2 1.333 0 3 .667 5 2V6c-2-1.333-3.667-2-5-2-1.333 0-2.667.667-4 2z"
					/>
					<path stroke-linecap="round" d="M12 6v14" />
				</svg>
				<div class="heading__title">{{ group.title }}: {{ locale[lang].GroupScheduleTitle }}</div>
			</div>
			<v-data-table
				v-if="!isMobile()"
				:headers="headers"
				:items="schedule"
				hide-default-footer
				style="margin-bottom: 40px"
				mobile-breakpoint="0"
			>
				<template v-slot:item.time_of_work="{ item }">
					<b>{{ item.time_of_work }}</b>
				</template>
				<template v-slot:item.pair="{ item }">
					<b>{{ item.pair }}</b>
				</template>
				<template v-slot:item.monday="{ item }">
					<b>{{ item.monday }}</b>
					<br>
					<span class="grey--text">{{ item.monday_teacher }}</span>
				</template>
				<template v-slot:item.tuesday="{ item }">
					<b>{{ item.tuesday }}</b>
					<br>
					<span class="grey--text">{{ item.tuesday_teacher }}</span>
				</template>
				<template v-slot:item.wednesday="{ item }">
					<b>{{ item.wednesday }}</b>
					<br>
					<span class="grey--text">{{ item.wednesday_teacher }}</span>
				</template>
				<template v-slot:item.thursday="{ item }">
					<b>{{ item.thursday }}</b>
					<br>
					<span class="grey--text">{{ item.thursday_teacher }}</span>
				</template>
				<template v-slot:item.friday="{ item }">
					<b>{{ item.friday }}</b>
					<br>
					<span class="grey--text">{{ item.friday_teacher }}</span>
				</template>
				<template v-slot:item.saturday="{ item }">
					<b>{{ item.saturday }}</b>
					<br>
					<span class="grey--text">{{ item.saturday_teacher }}</span>
				</template>
			</v-data-table>
			<div
				v-if="isMobile()"
				class="works"
			>
				<div
					v-if="!schedule.length"
					class="heading"
				>
					<div class="heading__title">{{ locale[lang].nothing_found }}</div>
				</div>
				<div v-else>
					<div class="work">
						<div class="work__day is-monday">{{ locale[lang].monday }}</div>
						<div class="work__items">
							<div
								v-for="(item, index) in schedule"
								:key="'monday-' + item.id"
								class="work__item"
							>
								<div class="work__item-time">
									<b>{{ item.time_of_work }}</b>
								</div>
								<div class="work__item-info">
									<b>{{ index + 1 }}. {{ item.monday }}</b>
									<br>
									<span class="work__item-teacher">{{ item.monday_teacher }}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="work">
						<div class="work__day is-tuesday">{{ locale[lang].tuesday }}</div>
						<div class="work__items">
							<div
								v-for="(item, index) in schedule"
								:key="'tuesday-' + item.id"
								class="work__item"
							>
								<div class="work__item-time">
									<b>{{ item.time_of_work }}</b>
								</div>
								<div class="work__item-info">
									<b>{{ index + 1 }}. {{ item.tuesday }}</b>
									<br>
									<span class="work__item-teacher">{{ item.tuesday_teacher }}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="work">
						<div class="work__day is-wednesday">{{ locale[lang].wednesday }}</div>
						<div class="work__items">
							<div
								v-for="(item, index) in schedule"
								:key="'wednesday-' + item.id"
								class="work__item"
							>
								<div class="work__item-time">
									<b>{{ item.time_of_work }}</b>
								</div>
								<div class="work__item-info">
									<b>{{ index + 1 }}. {{ item.wednesday }}</b>
									<br>
									<span class="work__item-teacher">{{ item.wednesday_teacher }}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="work">
						<div class="work__day is-thursday">{{ locale[lang].thursday }}</div>
						<div class="work__items">
							<div
								v-for="(item, index) in schedule"
								:key="'thursday-' + item.id"
								class="work__item"
							>
								<div class="work__item-time">
									<b>{{ item.time_of_work }}</b>
								</div>
								<div class="work__item-info">
									<b>{{ index + 1 }}. {{ item.thursday }}</b>
									<br>
									<span class="work__item-teacher">{{ item.thursday_teacher }}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="work">
						<div class="work__day is-friday">{{ locale[lang].friday }}</div>
						<div class="work__items">
							<div
								v-for="(item, index) in schedule"
								:key="'friday-' + item.id"
								class="work__item"
							>
								<div class="work__item-time">
									<b>{{ item.time_of_work }}</b>
								</div>
								<div class="work__item-info">
									<b>{{ index + 1 }}. {{ item.friday }}</b>
									<br>
									<span class="work__item-teacher">{{ item.friday_teacher }}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="work">
						<div class="work__day is-saturday">{{ locale[lang].saturday }}</div>
						<div class="work__items">
							<div
								v-for="(item, index) in schedule"
								:key="'saturday-' + item.id"
								class="work__item"
							>
								<div class="work__item-time">
									<b>{{ item.time_of_work }}</b>
								</div>
								<div class="work__item-info">
									<b>{{ index + 1 }}. {{ item.saturday }}</b>
									<br>
									<span class="work__item-teacher">{{ item.saturday_teacher }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				class="back"
				@click="$router.push('/choose-group')"
			>
				<svg
					role="img"
					xmlns="http://www.w3.org/2000/svg"
					width="24px"
					height="24px"
					viewBox="0 0 24 24"
					aria-labelledby="chevronLeftIconTitle"
					stroke="#141a28"
					stroke-width="2"
					stroke-linecap="square"
					stroke-linejoin="miter"
					fill="none"
					color="#141a28"
					class="back__svg"
				>
					<title id="chevronLeftIconTitle">Chevron Left</title>
					<polyline points="14 18 8 12 14 6 14 6" />
				</svg>
				<div class="back__title">{{ locale[lang].to_back }}</div>
			</div>
		</v-col>
	</v-row>
</template>

<style>
.header-text {
	color: #2055d7 !important;
}
</style>

<script>
	import axios from 'axios'

	export default {
		name: 'GroupSchedule',
		props: [
			'locale'
		],
		data: () => ({
			headers: [{
				text: 'Время',
				value: 'time_of_work',
				align: 'center',
				sortable: false,
				class: 'header-text'
			}, {
				text: 'Пара',
				value: 'pair',
				align: 'center',
				sortable: false,
				class: 'header-text'
			}, {
				text: 'Понедельник',
				value: 'monday',
				align: 'center',
				sortable: false,
				class: 'header-text'
			}, {
				text: 'Вторник',
				value: 'tuesday',
				align: 'center',
				sortable: false,
				class: 'header-text'
			}, {
				text: 'Среда',
				value: 'wednesday',
				align: 'center',
				sortable: false,
				class: 'header-text'
			}, {
				text: 'Четверг',
				value: 'thursday',
				align: 'center',
				sortable: false,
				class: 'header-text'
			}, {
				text: 'Пятница',
				value: 'friday',
				align: 'center',
				sortable: false,
				class: 'header-text'
			}, {
				text: 'Суббота',
				value: 'saturday',
				align: 'center',
				sortable: false,
				class: 'header-text'
			}],
			schedule: []
		}),
		computed: {
			backend_url () {
				return this.$store.getters.BACKEND_URL
			},
			lang () {
				return this.$store.getters.LANG
			},
			group () {
				return this.$route.params.group
			}
		},
		mounted () {
			if(this.group) {
				window.scroll(0, 0)

				this.$store.commit('SET_PRELOADER', true)

				axios
					.get(this.backend_url + '/api/schedule/' + this.group.id)
					.then(response => {
						this.schedule = response.data
					})
					.finally(() => {
						this.$store.commit('SET_PRELOADER', false)
					})
			} else {
				this.$router.push('/choose-group')
			}
		},
		methods: {
			isMobile () {
				let result = false

				if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
					result = true
				}

				return result
			}
		}
	}
</script>
