import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../views/Home';

import ChooseLang from '../views/ChooseLang';
import ChooseShift from '../views/ChooseShift';
import ChooseCourse from '../views/ChooseCourse';
import ChooseGroup from '../views/ChooseGroup';
import GroupSchedule from '../views/GroupSchedule';
import PageShow from '../views/PageShow';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/choose-lang',
		name: 'ChooseLang',
		component: ChooseLang,
	},
	{
		path: '/choose-shift',
		name: 'ChooseShift',
		component: ChooseShift,
	},
	{
		path: '/choose-course',
		name: 'ChooseCourse',
		component: ChooseCourse,
	},
	{
		path: '/choose-group',
		name: 'ChooseGroup',
		component: ChooseGroup,
	},
	{
		path: '/group-schedule',
		name: 'GroupSchedule',
		component: GroupSchedule,
	},
	{
		path: '/pages/:id',
		name: 'PageShow',
		component: PageShow,
	},
];

const router = new VueRouter({
	routes,
});

export default router;
