<template>
	<v-row
		align="center"
		justify="center"
	>
		<v-col
			cols="11"
			xl="4"
			lg="4"
			md="6"
			sm="10"
		>
			<div class="logotype">
				<img src="../assets/logotype.png" alt="" class="logotype__img">
			</div>
			<div
				class="back"
				@click="$router.push('/')"
			>
				<svg
					role="img"
					xmlns="http://www.w3.org/2000/svg"
					width="24px"
					height="24px"
					viewBox="0 0 24 24"
					aria-labelledby="chevronLeftIconTitle"
					stroke="#141a28"
					stroke-width="2"
					stroke-linecap="square"
					stroke-linejoin="miter"
					fill="none"
					color="#141a28"
					class="back__svg"
				>
					<title id="chevronLeftIconTitle">Chevron Left</title>
					<polyline points="14 18 8 12 14 6 14 6" />
				</svg>
				<div class="back__title">{{ locale[lang].to_home }}</div>
			</div>
			<!--<div class="navs">
				<div
					class="nav"
					@click="$router.push('/choose-shift')"
				>
					<svg
						role="img"
						xmlns="http://www.w3.org/2000/svg"
						width="24px"
						height="24px"
						viewBox="0 0 24 24"
						aria-labelledby="chevronLeftIconTitle"
						stroke="#141a28"
						stroke-width="2"
						stroke-linecap="square"
						stroke-linejoin="miter"
						fill="none"
						color="#141a28"
						class="nav__svg"
					>
						<title id="chevronLeftIconTitle">Chevron Left</title>
						<polyline points="14 18 8 12 14 6 14 6" />
					</svg>
				</div>
				<div
					class="nav"
					@click="$router.push('/')"
				>
					<svg
						role="img"
						xmlns="http://www.w3.org/2000/svg"
						width="24px"
						height="24px"
						viewBox="0 0 24 24"
						aria-labelledby="laptopIconTitle"
						stroke="#141a28"
						stroke-width="2"
						stroke-linecap="square"
						stroke-linejoin="miter"
						fill="none"
						color="#141a28"
						class="nav__svg"
					>
						<title id="laptopIconTitle">Laptop</title>
						<path d="M20 7v11H4V7a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1z" />
						<path d="M2 18h20M4 17h16" />
					</svg>
				</div>
			</div>-->
			<div class="heading">
				<svg
					role="img"
					xmlns="http://www.w3.org/2000/svg"
					width="32px"
					height="32px"
					viewBox="0 0 24 24"
					aria-labelledby="bookOpenedIconTitle"
					stroke="#141a28"
					stroke-width="1.5"
					stroke-linecap="square"
					stroke-linejoin="miter"
					fill="none"
					color="#141a28"
					class="heading__svg"
				>
					<title id="bookOpenedIconTitle">Book</title>
					<path
						d="M12 6s-2-2-4-2-5 2-5 2v14s3-2 5-2 4 2 4 2c1.333-1.333 2.667-2 4-2 1.333 0 3 .667 5 2V6c-2-1.333-3.667-2-5-2-1.333 0-2.667.667-4 2z"
					/>
					<path stroke-linecap="round" d="M12 6v14" />
				</svg>
				<div class="heading__title">{{ locale[lang].ChooseCourseTitle }}</div>
			</div>
			<div class="checkers">
				<div
					v-for="item in courses"
					:key="item.key"
					:class="item.key === course ? 'checker is-active' : 'checker'"
					@click="changeCourse(item)"
				>
					<div class="checker__status">
						<svg
							role="img"
							xmlns="http://www.w3.org/2000/svg"
							width="16px"
							height="16px"
							viewBox="0 0 24 24"
							aria-labelledby="okIconTitle"
							stroke="#2055d7"
							stroke-width="3"
							stroke-linecap="square"
							stroke-linejoin="miter"
							fill="none"
							color="#2055d7"
							class="checker__status-svg"
						>
							<title id="okIconTitle">Ok</title>
							<polyline points="4 13 9 18 20 7" />
						</svg>
					</div>
					<div class="checker__title">{{ item.value[lang] }}</div>
				</div>
			</div>
			<div
				:class="course ? 'go is-active' : 'go'"
				@click="$router.push('/choose-group')"
			>
				<svg
					role="img"
					xmlns="http://www.w3.org/2000/svg"
					width="24px"
					height="24px"
					viewBox="0 0 24 24"
					aria-labelledby="chevronRightIconTitle"
					stroke="#a1abc3"
					stroke-width="2"
					stroke-linecap="square"
					stroke-linejoin="miter"
					fill="none"
					color="#a1abc3"
					class="go__svg"
				>
					<title id="chevronRightIconTitle">Chevron Right</title>
					<polyline points="10 6 16 12 10 18 10 18" />
				</svg>
				<div class="go__title">{{ locale[lang].go }}</div>
			</div>
			<div
				class="back"
				@click="$router.push('/choose-shift')"
			>
				<svg
					role="img"
					xmlns="http://www.w3.org/2000/svg"
					width="24px"
					height="24px"
					viewBox="0 0 24 24"
					aria-labelledby="chevronLeftIconTitle"
					stroke="#141a28"
					stroke-width="2"
					stroke-linecap="square"
					stroke-linejoin="miter"
					fill="none"
					color="#141a28"
					class="back__svg"
				>
					<title id="chevronLeftIconTitle">Chevron Left</title>
					<polyline points="14 18 8 12 14 6 14 6" />
				</svg>
				<div class="back__title">{{ locale[lang].to_back }}</div>
			</div>
		</v-col>
	</v-row>
</template>

<script>
	export default {
		name: 'ChooseCourse',
		props: [
			'locale'
		],
		data: () => ({
			courses: [{
				key: 1,
				value: {
					kz: '1 курс',
					ru: '1 курс',
					en: '1 course'
				}
			}, {
				key: 2,
				value: {
					kz: '2 курс',
					ru: '2 курс',
					en: '2 course'
				}
			}, {
				key: 3,
				value: {
					kz: '3 курс',
					ru: '3 курс',
					en: '3 course'
				}
			}]
		}),
		computed: {
			lang () {
				return this.$store.getters.LANG
			},
			course () {
				return this.$store.getters.COURSE
			}
		},
		mounted () {
			window.scroll(0, 0)
		},
		methods: {
			changeCourse (course) {
				this.$store.commit('SET_COURSE', course.key)
			}
		}
	}
</script>
